/**
 * @typedef {string} vehicleTypes
 */

/**
 * Vehicle types
 *
 * @enum {vehicleTypes}
 */
export default {
    CAR: 'CAR',
    TRUCK: 'TRUCK',
    CAMPER: 'CAMPER',
    MOTORBIKE: 'MOTORBIKE',
};
